import { HttpErrorResponse } from '@angular/common/http'
import { createAction, props } from '@ngrx/store'

export const openMenu = createAction('[stage] Open Menu')

export const closeMenu = createAction('[stage] Close Menu')

export const toggleMenu = createAction('[stage] Toggle Menu')

export const sendFeedback = createAction('[stage] Sendind feedback', props<{ request: string }>())

export const sendFeedbackSuccess = createAction('[stage] Sendind feedback SUCCESS')

export const sendFeedbackFailure = createAction('[stage] Sendind feedback FAILURE', props<{ error: HttpErrorResponse }>())

export const setUser = createAction('[stage] Setting user', props<{ user: string }>())
